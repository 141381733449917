.outline-none.PhoneInput.contactnum input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    height: 2.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-space-y-reverse: 0;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
    padding: 10px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgb(148, 148, 148);
}

.outline-none.PhoneInput.contactnum input:focus {
    --tw-border-opacity: 1;
    border-color: rgba(var(--c-primary-300), var(--tw-border-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0;
    border-color: #7352C7;
    border-width: 0.13rem;
}

.PhoneInput {
    max-width: 14rem;
    display: flex;
    align-items: center;
}

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
    margin-right: 0.35em;
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountryIcon {
    width: calc(1em * 1.5);
    width: calc(1em * 1.5);
    height: 1em;
    height: 1em;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    width: 0.3em;
    height: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: currentColor;
    border-color: currentColor;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    border-right-width: 1px;
    transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0.45;
    opacity: 0.45;
}

.PhoneInputInput {
    flex: 1;
    min-width: 0;
}